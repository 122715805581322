import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function Grants() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title='Гранты'
      />

      <section className='flex flex-col items-center md:flex-row'>
        <div className='md:w-2/3 md:mr-8'>
          <h1>Гранты</h1>
        </div>
      </section>
    </Layout>
  );
}

export default Grants;
